import React from "react";
import Login from '../components/forms/Login'


function LoginPage() {

  return (
    <div className="center m-5 p-5 text-center d-flex align-items-center justify-content-center">
      <div className="mt-5 pt-5"><Login /></div>
    </div>
  );
}

export default LoginPage;
