import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Table, Tooltip } from "antd";
import axios from "axios";

import { MoreOutlined, PushpinOutlined, SendOutlined } from "@ant-design/icons";

import profileLink from "../assets/svg/select.svg";
import { closeIcon, selectIcon } from "../assets/svg/icons";
import { getContrastColor, getRandomColor } from "../../helpers";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import SimpleSpinner from "../spinners/SimpleSpinner";
import { ReactComponent as ExpandIcon } from "../assets/svg/right-chevron.svg";

const MessageColumn = ({ text, record }) => {
  const { id } = record;

  console.log(record.last_messages);

  const [lastMessages, setLastMessages] = useState([]);
  // useEffect(() => {
  //   record && setLastMessages(record.last_messages);
  // }, [record]);

  const [message, setMessage] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  // const generateAIMessage = async () => {
  //   const token = localStorage.getItem("access_token");
  //   const response = await axios.get(
  //     `https://hrsystem.pogovorimo.com.ua/api/offer/${id}/messages/ai`,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": "Bearer " + token,
  //       },
  //     }
  //   );
  //   return response.data;
  // };

  // const { mutate: requestAiMessage, isLoading: isAiMessageLoading } =
  //   useMutation(generateAIMessage, {
  //     onSuccess: (responseData) => {
  //       setMessage(responseData.message);
  //     },
  //   });

  const sendMessage = async (applicant_offer) => {
    const token = localStorage.getItem("access_token");
    await axios.post(
      "https://hrsystem.pogovorimo.com.ua/api/message/create/",
      {
        direction_type: "outgoing",
        text: message,
        applicant_offer: applicant_offer,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
      }
    );
  };

  const { mutate: requestSendMessage } = useMutation(sendMessage, {
    onSuccess: () => {
      toast.success("Your message has been sent");
      setLastMessages((prev) => [...prev, message]);
    },
  });

  const refetchMessages = async () => {
    const token = localStorage.getItem("access_token");
    const response = await axios.get(
      `https://hrsystem.pogovorimo.com.ua/api/offer/${id}/messages/incoming`,

      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
      }
    );
    return response.data;
  };

  const { mutate: requestRefetchMessages } = useMutation(refetchMessages, {
    onSuccess: (responseData) => {
      setLastMessages(responseData.map((message) => message.text));
    },
  });

  useEffect(() => {
    record && requestRefetchMessages();
  }, [record]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
        onMouseLeave={() => setIsExpanded(false)}
      >
        {lastMessages.at(-1) !== undefined && (
          <>
            <button
              onClick={() => setIsExpanded((prev) => !prev)}
              style={{
                background: "transparent",
                border: "none",
                position: "absolute",
                right: "10px",
                top: "8px",
                rotate: isExpanded ? "270deg" : "90deg",
                transition: "all 0.35s ease-in-out",
                outline: "none",
              }}
            >
              <ExpandIcon style={{ width: "15px", height: "15px" }} />
            </button>
          </>
        )}
        <div
          className="messages-scroll"
          style={{
            height: isExpanded ? 150 : 40,
            overflow: "hidden",
            borderRadius: 8,
            overflowY: "scroll",
            transition: "all 0.35s ease-in-out",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {lastMessages.at(-1) === undefined && (
              <>
                <p
                  style={{
                    flex: "1 1 0%",
                    margin: 0,
                    color: "#262626",
                    fontSize: 12,
                    padding: "12px 16px 8px",
                    backgroundColor: "#EEF1F2",
                    borderRadius: 4,
                    cursor: "crosshair",
                    minHeight: "40px",
                  }}
                ></p>
              </>
            )}
            {lastMessages.at(-1) !== undefined && (
              <>
                {!isExpanded && (
                  <>
                    <p
                      style={{
                        flex: "1 1 0%",
                        margin: 0,
                        color: "#262626",
                        fontSize: 12,
                        padding: "12px 16px 8px",
                        backgroundColor: "#EEF1F2",
                        borderRadius: 4,
                        cursor: "crosshair",
                      }}
                    >
                      {lastMessages.at(-1) !== undefined && (
                        <>
                          {lastMessages.at(-1).length <= 96
                            ? lastMessages.at(-1)
                            : `${lastMessages.at(-1).slice(0, 96)}...`}
                        </>
                      )}
                    </p>
                  </>
                )}
                {isExpanded && (
                  <>
                    {lastMessages.map((message, index) => (
                      <p
                        style={{
                          flex: "1 1 0%",
                          margin: 0,
                          color: "#262626",
                          fontSize: 12,
                          padding: "12px 16px 8px",
                          backgroundColor: "#EEF1F2",
                          borderRadius: 4,
                          cursor: "crosshair",
                          minHeight: "30px",
                        }}
                        key={index}
                      >
                        {message}
                      </p>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: 4,
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            border: "2px solid #eee",
            borderRadius: "8px",
          }}
        >
          {/* <InputComponent defaultText={defaultText} offerId={offerId} /> */}
          <input
            type="text"
            placeholder="Type your text here"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            style={{
              flex: "1 1 0%",
              height: 40,
              borderRadius: "8px 0 0 8px",
              fontSize: 12,
              padding: "0 12px",
              border: "none",
              outline: "none",
            }}
          />
          <div style={{ display: "flex" }}>
            <SendOutlined
              style={{
                zIndex: 1,
                cursor: "pointer",
                paddingLeft: 2,
                right: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 40,
                backgroundColor: "#ECF6FE",
                color: "#69C0FF",
              }}
              onClick={() => requestSendMessage(record.id)}
            />
            {/* <button
              onClick={() => requestAiMessage()}
              style={{
                padding: "5px 20px",
                border: "none",
                fontWeight: 500,
                fontSize: "14px",
                color: "rgb(0,0,0,0.65)",
              }}
            >
              {isAiMessageLoading && (
                <>
                  <SimpleSpinner />
                </>
              )}
              {!isAiMessageLoading && (
                <>
                  <span>AI</span>
                </>
              )}
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

const UserInfoList = ({ userInfo }) => {
  const dataToDisplay = [
    {
      title: "Is shared contacts",
      value: userInfo["is_shared_contacts"] ? "🟩" : "🟥",
    },
    {
      title: "Djinni id",
      value: userInfo["djinni_id"],
    },
    {
      title: "Candidate name",
      value: userInfo["candidate_name"],
    },
    {
      title: "Candidate details title",
      value: userInfo["details_title"],
    },
    {
      title: "Work experience",
      value: userInfo["experience"],
    },
    {
      title: "Skills",
      value: userInfo["skills"],
    },
    {
      title: "Achievements",
      value: userInfo["achievements"],
    },
    {
      title: "Languages",
      value: userInfo["languages"],
    },
    {
      title: "Other info",
      value: userInfo["other_info"],
    },
    {
      title: "Applicant photo",
      value: userInfo[""],
    },
    {
      title: "Country",
      value: userInfo["country"],
    },
    {
      title: "Location",
      value: userInfo["location"],
    },
    {
      title: "Email",
      value: userInfo["email"],
    },
    {
      title: "Phone number",
      value: userInfo["phone_number"],
    },
    {
      title: "Telegram",
      value: userInfo["telegram"],
    },
    {
      title: "GitHub",
      value: <a href={userInfo["gitHub"]}>{userInfo["gitHub"]}</a>,
    },
    {
      title: "Linkedin",
      value: <a href={userInfo["linkedin"]}>{userInfo["linkedin"]}</a>,
    },
    {
      title: "CV profile link",
      value: (
        <div style={{ maxWidth: "300px", width: "100%" }}>
          <a href={userInfo["cv_profile_link"]}>
            {userInfo["cv_profile_link"]}
          </a>
        </div>
      ),
    },
    {
      title: "CV opened link",
      value: (
        <div style={{ maxWidth: "300px", width: "100%" }}>
          <a href={userInfo["cv_opened_link"]}>{userInfo["cv_opened_link"]}</a>
        </div>
      ),
    },
    {
      title: "Additional link (portfolio)",
      value: (
        <div style={{ maxWidth: "300px", width: "100%" }}>
          <a href={userInfo["additional_link"]}>
            {userInfo["additional_link"]}
          </a>
        </div>
      ),
    },
  ];

  return (
    <ul
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        maxHeight: "500px",
        overflowY: "scroll",
        margin: "0",
        marginTop: "20px",
        padding: "0 10px 0 20px",
      }}
    >
      {dataToDisplay.map((item) => {
        const { title, value } = item;
        return (
          <>
            <li style={{ display: "flex", gap: "15px" }}>
              <div
                style={{
                  fontWeight: 500,
                  maxWidth: "30%",
                  flex: "0 0 30%",
                }}
              >
                {title}
              </div>
              <div>{value}</div>
            </li>
          </>
        );
      })}
    </ul>
  );
};

const UserInfoPopup = ({ userInPopup, setUserInPopup }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (userInPopup) {
      (async () => {
        const token = localStorage.getItem("access_token");
        try {
          const response = await axios.get(
            `https://hrsystem.pogovorimo.com.ua/api/applicant/${userInPopup}`,
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
              },
            }
          );
          response.status == 200 && setUserInfo(response.data);
          console.log(response.data);
        } catch (e) {
          setError(e.message);
        }
      })();
    }
  }, [userInPopup]);

  useEffect(() => {
    const handleEvent = (e) => {
      if (e.key == "Escape") {
        setUserInPopup(null);
        setUserInfo(null);
      }
    };

    document.addEventListener("keydown", handleEvent);

    return () => document.removeEventListener("keydown", handleEvent);
  }, []);

  return (
    <>
      <div
        data-name="popup-wrapper"
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          height: "100%",
          width: "100%",
          backgroundColor: "rgb(0,0,0,0.15)",
          zIndex: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          visibility: `${userInPopup ? "visible" : "hidden"}`,
          padding: "0 10px",
        }}
        onClick={(e) => {
          if (e.target.dataset.name == "popup-wrapper") {
            setUserInPopup(null);
            setUserInfo(null);
          }
        }}
      >
        <div
          style={{
            maxWidth: "500px",
            width: "100%",
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "20px 0px",
            minHeight: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <button
            onClick={() => {
              setUserInPopup(null);
              setUserInfo(null);
            }}
            style={{
              width: "30px",
              height: "30px",
              position: "absolute",
              border: "none",
              background: "transparent",
              top: "5px",
              right: "5px",
            }}
          >
            {closeIcon}
          </button>

          {error && (
            <>
              <div
                style={{
                  fontWeight: 500,
                  fontSize: "21px",
                }}
              >
                {error}
              </div>
            </>
          )}
          {!error && (
            <>
              {userInfo && <UserInfoList userInfo={userInfo} />}
              {!userInfo && (
                <>
                  <div class="lds-dual-ring" style={{ scale: "0.75" }}></div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const UserColumn = ({ text, record }) => {
  const [userInPopup, setUserInPopup] = useState(null);

  let icon = selectIcon(record.status);
  const bgColor = getRandomColor();
  const color = getContrastColor(bgColor);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#EEFFDF",
          borderRadius: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            paddingTop: 10,
            flexDirection: "column",
            justifyContent: "space-between",
            height: 80,
          }}
        >
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <Tooltip
              title={`${record.name} ${record.surname}`}
              color={tooltipColor}
            >
              {record.link_to_photo ? (
                <Avatar
                  src={<img src={`${record.link_to_photo}`} alt="avatar" />}
                  style={{ width: 32, height: 32 }}
                />
              ) : (
                <Avatar
                  style={{
                    width: 32,
                    height: 32,
                    backgroundColor: bgColor,
                    color: color,
                    textTransform: "uppercase",
                    padding: 0,
                    margin: 0,
                    letterSpacing: -2,
                  }}
                  onClick={() => {
                    setUserInPopup(record.applicant_id);
                  }}
                >
                  {record.name ? record.name.substring(0, 1) : "U"}{" "}
                  {record.surname ? record.surname.substring(0, 1) : "N"}
                </Avatar>
              )}
            </Tooltip>
            <div style={{ marginLeft: "10px" }}>
              <div>
                <span style={{ margin: "0 20px 0 30px" }}>{icon}</span>
                <PushpinOutlined size={20} style={{ color: "#8C8C8C" }} />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: 56,
            }}
          >
            <span style={{ color: "#8C8C8C", fontSize: 12, paddingTop: 8 }}>
              {record.djinni_date}
            </span>
            <a href={`${record.link_to_profile}`} target="_blank">
              <img
                src={profileLink}
                style={{ width: 16 }}
                alt="Link to profile"
              />
            </a>
          </div>
        </div>
      </div>
      <UserInfoPopup
        userInPopup={userInPopup}
        setUserInPopup={setUserInPopup}
      />
    </>
  );
};

const tooltipColor = "#2db7f5";
const columns = [
  {
    title: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <input type="checkbox" />
      </div>
    ),
    dataIndex: "checkbox",
    key: "checkbox",
    width: "5%",
    render: () => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <input type="checkbox" />
      </div>
    ),
  },
  {
    title: <div style={{ fontSize: 12, textAlign: "center" }}>ID</div>,
    dataIndex: "ID",
    key: "id",
    width: "5%",
    render: (text, record) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span style={{ color: "#8c8c8c", fontSize: 12 }}>{record.id}</span>
      </div>
    ),
  },
  {
    title: <div style={{ fontSize: 12, textAlign: "center" }}>User</div>,
    dataIndex: "user",
    key: "user",
    width: "16%",
    render: (text, record) => {
      return <UserColumn text={text} record={record} />;
    },
  },
  {
    title: <div style={{ fontSize: 12, textAlign: "center" }}>Messages</div>,
    dataIndex: "messages",
    key: "messages",
    width: "70%",

    render: (text, record) => {
      return <MessageColumn text={text} record={record} />;
    },
  },
  {
    title: (
      <div style={{ fontSize: 12, textAlign: "center" }}>
        <MoreOutlined size={20} style={{ color: "#8c8c8c" }} />
      </div>
    ),
    dataIndex: "actions",
    key: "actions",
    width: "3%",

    render: () => {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MoreOutlined size={20} style={{ color: "#8c8c8c" }} />
          </div>
          ;
        </>
      );
    },
  },
];

const OfferList = ({ filter }) => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 0,
  });

  const fetchData = useCallback(
    async (page = 1, pageSize = 20) => {
      const URL = `https://hrsystem.pogovorimo.com.ua/api/offer/extended_list/?page=${page}&page_size=${pageSize}${
        filter.status.length
          ? `&status=${filter.status.reduce((a, b) => `${a},${b}`)}`
          : ""
      }`;
      try {
        const { data } = await axios.get(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        const formattedData = data.results.map((user, index) => {
          return {
            key: index,
            id: user.id,
            applicant_id: user.applicant_id,
            status: user.status,
            djinni_date: user.djinni_date,
            archived: user.archived,
            name: user.name,
            surname: user.surname,
            link_to_photo: user.link_to_photo,
            link_to_profile: user.link_to_profile,
            last_messages: user.last_messages,
          };
        });
        setData(formattedData);
        setPagination({
          ...pagination,
          current: page,
          total: data.count,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [filter]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, filter]);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    fetchData(current, pageSize);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={pagination}
        onChange={handleTableChange}
        size={"small"}
        style={{ margin: 16 }}
      />
    </>
  );
};

export default OfferList;
