import {
  UserAddOutlined,
  UserDeleteOutlined,
  FileAddOutlined,
  MessageOutlined,
  FileTextOutlined,
  ExclamationCircleOutlined,
  FileSyncOutlined,
  FileExcelOutlined,
  FileExclamationOutlined,
  SafetyOutlined,
} from "@ant-design/icons";

export const selectIcon = (name) => {
  let icon;
  switch (name) {
    case "new_opened":
      icon = <UserAddOutlined size={20} style={{ color: "#EC7272" }} />;
      break;
    case "new_closed":
      icon = <UserDeleteOutlined size={20} style={{ color: "#05B9F1" }} />;
      break;
    case "test_sent":
      icon = <FileAddOutlined size={20} style={{ color: "#05B9F1" }} />;
      break;
    case "user_question":
      icon = <MessageOutlined size={20} style={{ color: "#F1A105" }} />;
      break;
    case "test_start":
      icon = <FileTextOutlined size={20} style={{ color: "#05B9F1" }} />;
      break;
    case "note_after_deadline":
      icon = (
        <ExclamationCircleOutlined size={20} style={{ color: "#F1A105" }} />
      );
      break;
    case "test_in_progress":
      icon = <FileSyncOutlined size={20} style={{ color: "#05B9F1" }} />;
      break;
    case "user_left":
      icon = <FileExcelOutlined size={20} style={{ color: "#EC7272" }} />;
      break;
    case "user_lost":
      icon = <FileExclamationOutlined size={20} style={{ color: "#F1A105" }} />;
      break;
    case "test_done":
      icon = <SafetyOutlined size={20} style={{ color: "#78C72A" }} />;
      break;
    default:
      break;
  }
  return icon;
};

export const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="100%"
    height="100%"
  >
    <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
  </svg>
);
