import React, { useEffect, useState } from "react";
import axios from "axios";
import logoutSVG from "../../src/components/assets/svg/logout.svg";

import { Layout, Space } from "antd";
//import ListingUsers from '../components/ListingUsers'
//import Offers from "../components/list/Offers";
//import Testing from "../components/list/Testing";
import OfferList from "../components/list/OfferList";
import { useNavigate } from "react-router";
import { FileSyncOutlined, UserAddOutlined } from "@ant-design/icons";
import { selectIcon } from "../components/assets/svg/icons";

const { Header, Footer, Sider, Content } = Layout;
const headerStyle = {
  textAlign: "center",
  color: "#fff",
  height: 64,
  paddingInline: 50,
  lineHeight: "64px",
  borderBottom: "1px solid #efefef",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "end",
};
const contentStyle = {
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#fff",
};
const siderStyle = {
  textAlign: "center",
  lineHeight: "120px",
  color: "#fff",
  borderRight: "1px solid #efefef",
  backgroundColor: "#fff",
};
const footerStyle = {
  textAlign: "center",
  color: "#fff",
  borderTop: "1px solid #efefef",
  backgroundColor: "#fff",
};

const LogoutBtn = ({ setUser }) => {
  const navigate = useNavigate();
  return (
    <>
      <button
        className="logout-btn hover-gray"
        onClick={() => {
          setUser(null);
          localStorage.removeItem("access_token");
          navigate("/");
        }}
      >
        <img style={{ width: "18px" }} src={logoutSVG} />
      </button>
    </>
  );
};

const UserProfile = ({ userData, setUser }) => {
  const { first_name: name, last_name: surname, email } = userData;

  return (
    <>
      <div
        style={{
          padding: "5px 20px 5px 10px",
          borderRight: "1px solid #efefef",
          borderLeft: "1px solid #efefef",
          color: "rgb(38, 38, 38)",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          gap: "8px",
          lineHeight: "normal",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: "30px",
          }}
        >
          <span style={{ fontWeight: "500" }}>User:</span>
          <span>
            {name} {surname}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: "30px",
          }}
        >
          <span style={{ fontWeight: "500" }}>Email:</span> <span>{email}</span>
        </div>
      </div>
      <LogoutBtn setUser={setUser} />
    </>
  );
};

const FilterBlock = ({ filter, addFilter }) => {
  const statuses = [
    {
      value: "new_opened",
    },
    {
      value: "test_in_progress",
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          lineHeight: "normal",
          marginTop: "16px",
        }}
      >
        <div style={{ color: "#262626" }}>
          <div
            style={{
              padding: "8px",
              fontWeight: 500,
              borderBottom: "1px solid #efefef",
            }}
          >
            Status
          </div>
          <ul style={{ padding: 0, margin: 0 }}>
            {statuses.map((status) => {
              const { value } = status;
              return (
                <li
                  style={{
                    padding: "20px 15px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "20px",
                    borderRadius: "15px",
                    borderBottom: "1px solid #efefef",
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                  onClick={() => {
                    addFilter((prev) => {
                      const copy = { ...prev };
                      if (copy.status.includes(value)) {
                        copy.status = copy.status.filter(
                          (status) => status != value
                        );
                      } else {
                        copy.status.push(value);
                      }
                      return copy;
                    });
                  }}
                >
                  <input
                    type="checkbox"
                    style={{
                      borderRadius: "2px",
                      border: "1.5px solid rgb(0,0,0,0.25)",
                      width: "12px",
                      height: "12px",
                      filter: "hue-rotate(240deg)",
                    }}
                    checked={filter.status.includes(value)}
                  />
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {selectIcon(value)}
                  </span>
                  <span style={{ fontWeight: 500 }}>
                    {value.split("_").join(" ")}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

const Admin = () => {
  const [message, setMessage] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = {
      token: localStorage.getItem("access_token"),
    };
    console.log(token);
    if (localStorage.getItem("access_token") === null) {
      window.location.href = "/";
    } else {
      (async () => {
        try {
          const { data } = await axios.post(
            "https://hrsystem.pogovorimo.com.ua/api/token/verify/",
            token,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          setMessage(data.message);
        } catch (e) {
          console.log("not auth");
          console.log(e);
        }
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          "https://hrsystem.pogovorimo.com.ua/api/users/me",
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + token,
            },
          }
        );
        response.status == 200 && setUser(response.data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const [filter, setFilter] = useState({
    status: [],
  });

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
      size={[0, 48]}
    >
      <Layout>

        <Header style={headerStyle}>
          {user && <UserProfile userData={user} setUser={setUser} />}
        </Header>
        <Layout>
          <Sider style={siderStyle}>
            <FilterBlock filter={filter} addFilter={setFilter} />
          </Sider>
          <Content style={contentStyle}>
            <Space>{message}</Space>
            <OfferList filter={filter} />
          </Content>
        </Layout>
        <Footer style={footerStyle}>Footer</Footer>
      </Layout>
    </Space>
  );
};

export default Admin;
