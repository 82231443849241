import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import AdminPage from './pages/AdminPage'

import './styles/custom.sass'
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function App() {

  return (
    <BrowserRouter>
      <div className="app">
        <ToastContainer
            position="top-left"
            autoClose={1500}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
        <Suspense fallback={<div className='w-100 h-100 bg-dark c-white flex jcc aic loading'>Loading...</div>}>
          <div className="bg-all">
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/admin" element={<AdminPage />} />
            </Routes>
          </div>
        </Suspense>
      </div>
    </BrowserRouter>
  )
}

export default App
